import { ReactNode } from 'react';
import Link from 'next/link';
import styles from './CommonLink.module.scss';
import { CommonLinkType, CommonLinkVariants, CommonLinkSizeVariants, CommonLinkSizeType } from './types';

const variants: CommonLinkType = {
  solid: styles.solid,
  outline: styles.outline,
  default: ''
};

const sizes: CommonLinkSizeType = {
  medium: styles.medium,
  big: styles.big,
  default: ''
};

interface CommonLinkProps {
  link: string;
  variant?: CommonLinkVariants;
  size?: CommonLinkSizeVariants;
  children?: ReactNode;
  className?: string;
  isBlank?: boolean;
}

export const CommonLink = ({
  link,
  variant = CommonLinkVariants.DEFAULT,
  size = CommonLinkSizeVariants.MEDIUM,
  className = '',
  children,
  isBlank = false,
  ...props
}: CommonLinkProps) => {
  return (
    <Link
      href={link}
      target={isBlank ? '_blank' : undefined}
      rel={isBlank ? 'noopener noreferrer' : undefined}
      className={`${variants[variant]} ${sizes[size]} ${className}`}
      {...props}
    >
      {children}
    </Link>
  );
};
