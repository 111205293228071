'use client';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { useIsVisible } from '@/src/app/hooks/useIsVisible';
import ETH from '@/public/icons/currency/eth.svg';
import LP from '@/public/icons/currency/lp.svg';
// import YEL from '@/public/icons/currency/yel.svg';
import ProvideArrowsIcon from '@/public/icons/howItWorks/provideArrows.svg';
import {
  containerVariants,
  wrapperVariants,
  firstCurrencyVariants,
  provideArrowsIconVariants,
  secondCurrencyVariants,
  thirdCurrencyVariants
} from './constants';
import styles from './ProvideAnimation.module.scss';

export const ProvideAnimation = () => {
  const { ref, controls, handleClick } = useIsVisible({ once: true, amount: 1 });

  return (
    <motion.div
      ref={ref}
      className={styles.container}
      variants={containerVariants}
      initial='hidden'
      animate={controls}
      onClick={handleClick}
    >
      <motion.div className={styles.currencyWrapper} variants={wrapperVariants}>
        <motion.div variants={firstCurrencyVariants} className={styles.wrapperEth}>
          <ETH className={styles.eth} />
        </motion.div>
        <motion.div variants={secondCurrencyVariants} className={styles.wrapperYel}>
          <Image
            priority
            alt='currency icon'
            src='/icons/currency/yel.png'
            width={95}
            height={95}
            className={styles.yel}
          />
          {/* <YEL className={styles.yel} /> */}
        </motion.div>
      </motion.div>
      <motion.div variants={thirdCurrencyVariants}>
        <LP className={styles.lp} />
      </motion.div>
      <motion.div className={styles.arrowsWrapper}>
        <motion.div variants={provideArrowsIconVariants} style={{ width: '100%' }}>
          <ProvideArrowsIcon />
        </motion.div>
      </motion.div>
    </motion.div>
  );
};
