'use client';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { useIsVisible } from '@/src/app/hooks/useIsVisible';
import USDC from '@/public/icons/currency/usdc.svg';
// import YEL from '@/public/icons/currency/yel.svg';
import TradeArrowsIcon from '@/public/icons/howItWorks/tradeArrows.svg';
import { wrapperVariants, firstCurrencyVariants, tradeArrowsIconVariants, secondCurrencyVariants } from './constants';
import styles from './TradeAnimation.module.scss';

export const TradeAnimation = () => {
  const { ref, controls, handleClick } = useIsVisible({ once: true, amount: 1 });

  return (
    <motion.div ref={ref} className={styles.container} initial='hidden' animate={controls} onClick={handleClick}>
      <motion.div variants={firstCurrencyVariants} className={styles.wrapperUsdc}>
        <USDC className={styles.usdc} />
      </motion.div>
      <motion.div className={styles.arrowsWrapper} variants={wrapperVariants}>
        <motion.div variants={tradeArrowsIconVariants} style={{ width: '100%' }}>
          <TradeArrowsIcon className={styles.tradeArrowsIcon} />
        </motion.div>
      </motion.div>
      <motion.div variants={secondCurrencyVariants} className={styles.wrapperYel}>
        {/* <YEL className={styles.yel} /> */}
        <Image
          priority
          alt='currency icon'
          src='/icons/currency/yel.png'
          width={100}
          height={100}
          className={styles.yel}
        />
      </motion.div>
    </motion.div>
  );
};
