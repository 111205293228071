import { AnimatePresence, motion } from 'framer-motion';
import { CommonLink } from '@/src/app/components/CommonLink/CommonLink';
import { CommonLinkSizeVariants, CommonLinkVariants } from '@/src/app/components/CommonLink/types';
import styles from './TitleBlock.module.scss';
import { Links } from '@/src/app/constants/links';

export const TitleBlock = ({ title }: { title: string }) => {
  const titleVariants = {
    initial: { opacity: 0, y: -50 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.3 } },
    exit: { opacity: 0, y: 50, transition: { duration: 0.1 } }
  };
  return (
    <div className={styles.wrapper}>
      <div>
        <p className={styles.subtitle}>Explore Mad Science</p>
        <AnimatePresence>
          <h1 className={styles.title}>
            <div>DeFi R&D Lab of</div>
            <motion.div variants={titleVariants} animate='animate' initial={'initial'} exit={'exit'} key={title}>
              {title}
            </motion.div>
          </h1>
        </AnimatePresence>
      </div>
      <CommonLink
        link={Links.WEBSITE}
        variant={CommonLinkVariants.SOLID}
        size={CommonLinkSizeVariants.BIG}
        className={styles.link}
      >
        Open App
      </CommonLink>
    </div>
  );
};
