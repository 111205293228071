import { useState, useEffect } from 'react';

export const useScreenSize = (): { windowHeight: number; windowWidth: number } => {
  const [windowWidth, setWindowWidth] = useState<number>((): number =>
    typeof window !== 'undefined' ? window.innerWidth : 0
  );
  const [windowHeight, setWindowHeight] = useState<number>((): number =>
    typeof window !== 'undefined' ? window.innerHeight : 0
  );

  useEffect((): undefined | (() => void) => {
    if (typeof window === 'undefined') return;

    function handleResize(): void {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);
    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { windowWidth, windowHeight };
};
