export enum CurrencyType {
  TOKEN = 'token',
  SL_TOKEN = 'sl-token',
  LP_PAIR = 'lp-pair',
  POTION = 'potion'
}

export interface CommonData {
  chainId: number;
  address: string;
  name: string;
  symbol: string;
}

export interface TvlData extends CommonData {
  tvl: number;
  lptvl?: number;
}

export interface AprData extends CommonData {
  holderapr: number;
  decimals?: number;
  lpapr?: number;
}

export interface AssetsData extends CommonData {
  type: CurrencyType;
  icon?: string;
  decimals?: number;
  native?: boolean;
}

export interface RewardData {
  chain: number;
  rewards: number;
}

// export interface PriceData extends Pick<CommonData, 'chainId' | 'address'> {
//   price: number;
//   liquid: boolean;
// }

export interface InitialData {
  tvls: TvlData[];
  aprs: AprData[];
  assets: AssetsData[];
  rewards: RewardData[];
  // yelPrice: PriceData;
}

export interface VaultsData extends AssetsData {
  color: string;
  title: string;
  holderapr: number;
  decimals?: number;
  lpapr?: number;
}
