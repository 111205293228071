import { ReactNode } from 'react';
import BLAST from '@/public/icons/blockchain/blast.svg';
import BASE from '@/public/icons/blockchain/base.svg';
import FANTOM from '@/public/icons/blockchain/ftm.svg';

export const testnetChainIds: number[] = [80084, 64165];

export const blockcainIcons: Record<number, ReactNode> = {
  [81457]: <BLAST />,
  [8453]: <BASE />,
  [250]: <FANTOM />
};
