export const Links = {
  TELEGRAM: 'https://t.me/yelfinance',
  DISCORD: 'https://discord.gg/5HRAprgaGV',
  TWITTER: 'https://x.com/yel_finance',
  MEDIUM: 'https://yel-finance.medium.com',
  WEBSITE: 'https://app.yel.finance/',
  POTIONS: 'https://app.yel.finance/potions',
  BRIDGE: 'https://app.yel.finance/bridge',
  DOCS: 'https://yield-enhancement-labs.gitbook.io/yield-enhancement-labs'
};
