'use client';
import { useIsVisible } from '@/src/app/hooks/useIsVisible';
import { motion } from 'framer-motion';
import styles from './AnimationOrbit.module.scss';
import {
  centralCircleVariants,
  circleVariants,
  containerCirclesVariants,
  containerVariants,
  orbitsVariants
} from './constants';

export const AnimationOrbits = () => {
  const { ref, controls, handleClick } = useIsVisible({ amount: 0.5 });

  return (
    <motion.div
      ref={ref}
      className={styles.container}
      variants={containerVariants}
      initial='hidden'
      animate={controls}
      onClick={handleClick}
    >
      <motion.div className={`${styles.circle} ${styles.centralCircle}`} variants={centralCircleVariants}></motion.div>
      <motion.div className={styles.wrapper} variants={containerCirclesVariants}>
        <motion.div className={`${styles.circle} ${styles.circle1}`} variants={circleVariants}></motion.div>
        <motion.div className={`${styles.circle} ${styles.circle2}`} variants={circleVariants}></motion.div>
        <motion.div className={`${styles.circle} ${styles.circle3}`} variants={circleVariants}></motion.div>
        <motion.div className={`${styles.circle} ${styles.circle4}`} variants={circleVariants}></motion.div>
        <motion.div className={`${styles.circle} ${styles.circle5}`} variants={circleVariants}></motion.div>
        <motion.div className={`${styles.circle} ${styles.circle6}`} variants={circleVariants}></motion.div>
      </motion.div>
      <motion.div className={styles.orbits} variants={orbitsVariants}></motion.div>
    </motion.div>
  );
};
