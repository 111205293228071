export enum CommonLinkVariants {
  SOLID = 'solid',
  OUTLINE = 'outline',
  DEFAULT = 'default'
}

export enum CommonLinkSizeVariants {
  MEDIUM = 'medium',
  BIG = 'big',
  DEFAULT = 'default'
}

export type CommonLinkType = {
  [key in CommonLinkVariants]: string;
};

export type CommonLinkSizeType = {
  [key in CommonLinkSizeVariants]: string;
};
