import { CommonLink } from '../CommonLink/CommonLink';
import { socialNetworksData } from './constants';
import { SocialNetworkType, SocialNetworkVariants } from './types';
import styles from './SocialNetworks.module.scss';

interface SocialNetworksProps {
  variant?: SocialNetworkVariants;
}

const variants: SocialNetworkType = {
  hero: styles.hero,
  footer: styles.footer
};

export const SocialNetworks = ({ variant = SocialNetworkVariants.HERO }: SocialNetworksProps) => {
  return (
    <div className={`${styles.wrapper} ${variants[variant]}`}>
      {socialNetworksData(styles.icon).map((item, index) => (
        <CommonLink link={item.link} key={index} isBlank className={styles.link} aria-label={item.label}>
          {item.icon}
        </CommonLink>
      ))}
    </div>
  );
};
