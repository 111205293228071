'use client';
import { motion } from 'framer-motion';
import { SocialNetworks } from '../../components/SocialNetworks/SocialNetworks';
import { InfoBlock } from './components/InfoBlock/InfoBlock';
import { TitleBlock } from './components/TitleBlock/TitleBlock';
import FlaskIcon from '@/public/icons/hero/flask.svg';
import styles from './Hero.module.scss';
import { useHeroAnimation } from './hooks/useHeroAnimation';
import { titles, gradientsVariants, gradients } from './constants';
import { InitialData } from '../../api/types';

export const Hero = ({ initialData }: { initialData: InitialData }) => {
  const { backgroundVariants, index } = useHeroAnimation();
  return (
    <motion.section className={styles.hero} variants={backgroundVariants} animate='animate' initial={false}>
      <div className={`${styles.container} container`}>
        <TitleBlock title={titles[index]} />
        <InfoBlock initialData={initialData} />
        <div className={styles.flaskWrapper}>
          <FlaskIcon />
          <motion.div
            key={index}
            className={styles.potionWrapper}
            variants={gradientsVariants}
            initial='initial'
            animate='animate'
          >
            {gradients[index]}
          </motion.div>
        </div>
      </div>
      <SocialNetworks />
    </motion.section>
  );
};
