import Twitter from '@/public/icons/socialMedia/twitter.svg';
import Discord from '@/public/icons/socialMedia/discord.svg';
import Telegram from '@/public/icons/socialMedia/telegram.svg';
import Medium from '@/public/icons/socialMedia/medium.svg';
import { Links } from '../../constants/links';

export const socialNetworksData = (className: string) => [
  {
    link: Links.TWITTER,
    icon: <Twitter className={className} />,
    label: 'Twitter'
  },
  {
    link: Links.DISCORD,
    icon: <Discord className={className} />,
    label: 'Discord'
  },
  {
    link: Links.TELEGRAM,
    icon: <Telegram className={className} />,
    label: 'Telegram'
  },
  {
    link: Links.MEDIUM,
    icon: <Medium className={className} />,
    label: 'Medium'
  }
];
