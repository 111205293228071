import PotionFirstIcon from '@/public/icons/hero/potion1.svg';
import PotionSecondIcon from '@/public/icons/hero/potion2.svg';
import PotionThirdIcon from '@/public/icons/hero/potion3.svg';
import styles from './Hero.module.scss';

export const colors = [
  'linear-gradient(180deg, #9dc5ee 0%, #cc92e0 50.14%, rgba(123, 60, 148, 0) 100%)',
  'linear-gradient(180deg, #C389F0 -31.44%, #E98EF7 12.13%, rgba(249, 146, 208, 0.31) 60.57%, rgba(255, 163, 163, 0.00) 100%)',
  'linear-gradient(180deg, #E98EF7 -21.22%, #F992D0 13.34%, rgba(255, 163, 163, 0.26) 50.81%, rgba(255, 224, 163, 0.00) 92.12%)'
];

export const titles = ['Smart & Real Yield', 'Assets Utilization', 'Arbitrage'];

export const gradients = [
  <PotionFirstIcon key={0} className={styles.potion} />,
  <PotionSecondIcon key={1} className={styles.potion} />,
  <PotionThirdIcon key={2} className={styles.potion} />
];

export const gradientsVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 1 } }
};
