import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/drops.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/currency/arbitrum.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/currency/avax.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/currency/base.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/currency/bera.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/currency/binance.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/currency/blast.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/currency/ftm.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/currency/optimism.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/currency/polygon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/poolMobile.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/sections/Chains/Chains.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/SectionTitle/SectionTitle.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["CurrencyAnimation"] */ "/vercel/path0/src/app/sections/Chains/components/CurrencyAnimation/CurrencyAnimation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Hero"] */ "/vercel/path0/src/app/sections/Hero/Hero.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/sections/HowItWorks/components/HowItWorksList/HowItWorksList.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["ProvideAnimation"] */ "/vercel/path0/src/app/sections/HowItWorks/components/ProvideAnimation/ProvideAnimation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TradeAnimation"] */ "/vercel/path0/src/app/sections/HowItWorks/components/TradeAnimation/TradeAnimation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WrapAnimation"] */ "/vercel/path0/src/app/sections/HowItWorks/components/WrapAnimation/WrapAnimation.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/sections/HowItWorks/HowItWorks.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["MetricList"] */ "/vercel/path0/src/app/sections/Metrics/components/MetricList/MetricList.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/sections/Metrics/Metrics.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/page.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["RoadMap"] */ "/vercel/path0/src/app/sections/RoadMap/RoadMap.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BackgroundAnimation"] */ "/vercel/path0/src/app/sections/Vaults/components/BackgroundAnimation/BackgroundAnimation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VaultList"] */ "/vercel/path0/src/app/sections/Vaults/components/VaultList/VaultList.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/sections/Vaults/Vaults.module.scss");
