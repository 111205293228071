import { useAnimation, useInView } from 'framer-motion';
import { useEffect, useRef } from 'react';

interface UseIsVisibleProps {
  startState?: string;
  endState?: string;
  once?: boolean;
  amount?: number;
}

export const useIsVisible = ({
  startState = 'visible',
  endState = 'hidden',
  once = false,
  amount = 0
}: UseIsVisibleProps = {}) => {
  const controls = useAnimation();
  const ref = useRef(null);
  const inView = useInView(ref, { once, amount });

  useEffect(() => {
    if (inView) {
      controls.start(startState);
    } else {
      controls.start(endState);
    }
  }, [controls, inView, startState, endState]);

  const handleClick = () => {
    controls.start(endState).then(() => {
      controls.start(startState);
    });
  };

  return { ref, controls, handleClick };
};
