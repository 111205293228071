'use client';
import { motion } from 'framer-motion';
import { useIsVisible } from '@/src/app/hooks/useIsVisible';
import ETH from '@/public/icons/currency/eth.svg';
import WETH from '@/public/icons/currency/weth.svg';
import ReturnIcon from '@/public/icons/howItWorks/return.svg';
import { wrapperVariants, firstCurrencyVariants, returnIconVariants, secondCurrencyVariants } from './constants';
import styles from './WrapAnimation.module.scss';

export const WrapAnimation = () => {
  const { ref, controls, handleClick } = useIsVisible({ once: true, amount: 1 });

  return (
    <motion.div ref={ref} className={styles.container} initial='hidden' animate={controls} onClick={handleClick}>
      <motion.div variants={firstCurrencyVariants} className={styles.wrapperEth}>
        <ETH className={styles.eth} />
      </motion.div>
      <motion.div className={styles.wrapper} variants={wrapperVariants}>
        <motion.div variants={returnIconVariants} style={{ width: '100%' }}>
          <ReturnIcon className={styles.returnIcon} />
        </motion.div>
      </motion.div>
      <motion.div variants={secondCurrencyVariants} className={styles.wrapperWeth}>
        <WETH className={styles.weth} />
      </motion.div>
    </motion.div>
  );
};
