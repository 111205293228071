'use client';
import { motion } from 'framer-motion';
import { initialVariants } from './constant';
import { useIsVisible } from '@/src/app/hooks/useIsVisible';
import { useScreenSize } from '@/src/app/hooks/useScreenSize';
import { InitialData } from '@/src/app/api/types';
import { SkeletonWrapper } from '@/src/app/components/SkeletonWrapper/SkeletonWrapper';
import styles from './MetricList.module.scss';
import { useMetricsData } from './hooks/useMetricsData';

export const MetricList = ({ initialData }: { initialData: InitialData }) => {
  const { ref, controls } = useIsVisible({ startState: 'start', endState: 'end', amount: 0.3 });
  const { windowWidth } = useScreenSize();
  const { metricsData } = useMetricsData(initialData);

  return (
    <motion.ul className={styles.list} ref={ref} animate={controls}>
      {metricsData.map(({ animation, title, value, variants }, index) => (
        <motion.li key={index} className={styles.item} variants={windowWidth > 1024 ? variants : initialVariants}>
          {animation}
          <div className={styles.wrapper}>
            <SkeletonWrapper isLoading={value !== 0 && !value} width={160} height={40}>
              <div className={styles.value}>{value}</div>
            </SkeletonWrapper>
            <div className={styles.title}>{title}</div>
          </div>
        </motion.li>
      ))}
    </motion.ul>
  );
};
