export const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5
    }
  }
};

export const containerCirclesVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

export const circleVariants = {
  hidden: {
    opacity: 0,
    translate: '0% 50%',
    transition: {
      duration: 0.1
    }
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.2
    }
  }
};

export const centralCircleVariants = {
  hidden: {
    scale: 0,
    rotate: -40,
    transition: {
      duration: 0.1
    }
  },
  visible: {
    scale: 1,
    rotate: 0,
    transition: {
      duration: 0.5
    }
  }
};

export const orbitsVariants = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.1
    }
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.7
    }
  }
};
