'use client';

import { useScreenSize } from '@/src/app/hooks/useScreenSize';
import { useIsClient } from '@/src/app/hooks/useIsClient';
import { calculatePosition, getRadiusData } from './utils';
import styles from './CurrencyAnimation.module.scss';

interface CurrencyAnimationProps {
  data: {
    logo: JSX.Element;
  }[];
  className?: string;
  isRotate?: boolean;
}

export const CurrencyAnimation = ({ data, className, isRotate }: CurrencyAnimationProps) => {
  const { windowWidth } = useScreenSize();
  const { isClient } = useIsClient();

  if (!isClient) return null;

  const radius = getRadiusData(windowWidth);
  const totalItems = data?.length || 0;

  return (
    <div className={`${styles.animationContent} ${className}`}>
      {data?.map((item, index: number) => {
        const { x, y } = calculatePosition(index, totalItems, radius);
        return (
          <span
            key={index}
            className={`${styles.logo} ${isRotate ? styles.rotate : ''} ${isClient ? styles.animate : ''}`}
            style={{
              top: `calc(50% + ${y}px)`,
              left: `calc(50% + ${x}px)`,
              transform: `translate(-50%, -50%) rotate(${(index / totalItems) * 360}deg)`
            }}
          >
            {item.logo}
          </span>
        );
      })}
    </div>
  );
};
