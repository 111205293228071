const inOut = {
  hidden: { opacity: 1 },
  visible: { opacity: 0, transition: { duration: 0.1 } }
};

const outIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.1 } }
};

export const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

export const puddleVariants = {
  ...outIn
};

export const firstBubleleVariants = {
  ...inOut
};

export const secondBubleleVariants = {
  ...inOut
};

export const thirdBubleleVariants = {
  ...inOut
};

export const fourBubleleVariants = {
  ...outIn
};

export const fiveBubleleVariants = {
  ...outIn
};

export const sixBubleleVariants = {
  ...outIn
};
