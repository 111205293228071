'use client';
import styles from './LineProgress.module.scss';
import { MobileRoadMapProps } from '../../types';

export const LineProgress = ({ roadMapData, className }: MobileRoadMapProps) => {
  const progress = String(
    (100 / roadMapData.length) *
      roadMapData.reduce((acc, { isReady }) => {
        return isReady ? (acc += 1) : acc;
      }, 0)
  );
  return <div className={`${styles.line} ${className}`} data-progress={progress}></div>;
};
