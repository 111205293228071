import { InitialData } from '@/src/app/api/types';
import { useInfoData } from './hooks/useInfoData';
import styles from './InfoBlock.module.scss';
import { SkeletonWrapper } from '@/src/app/components/SkeletonWrapper/SkeletonWrapper';

export const InfoBlock = ({ initialData }: { initialData: InitialData }) => {
  const { infoData } = useInfoData(initialData);
  return (
    <ul className={styles.list}>
      {infoData.map(({ title, value }) => (
        <li key={title} className={styles.item}>
          <div className={styles.title}>{title}</div>
          <SkeletonWrapper isLoading={!value} className={styles.valueSkeleton}>
            <div className={styles.value}>{value}</div>
          </SkeletonWrapper>
        </li>
      ))}
    </ul>
  );
};
