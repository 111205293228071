import Skeleton from 'react-loading-skeleton';

interface SkeletonWrapperProps {
  isLoading: boolean;
  width?: number;
  height?: number;
  className?: string;
  isCircle?: boolean;
  skeleton?: boolean;
  children?: React.ReactNode;
  baseColor?: string;
  highlightColor?: string;
}

export const SkeletonWrapper = ({
  isLoading,
  width,
  height,
  className,
  isCircle,
  children,
  baseColor = '#9e9a9a',
  highlightColor = '#b6b5b5'
}: SkeletonWrapperProps) => {
  return (
    <>
      {isLoading ? (
        <Skeleton
          className={className}
          circle={isCircle}
          width={width}
          height={height}
          baseColor={baseColor}
          highlightColor={highlightColor}
        />
      ) : (
        children
      )}
    </>
  );
};
