import PointIcon from '@/public/icons/point.svg';
import CheckPoint from '@/public/icons/checkPoint.svg';
import styles from './MobileRoadMap.module.scss';
import { LineProgress } from '../LineProgress/LineProgress';
import { MobileRoadMapProps } from '../../types';

export const MobileRoadMap = ({ roadMapData, className }: MobileRoadMapProps) => {
  return (
    <div className={styles.container}>
      <LineProgress className={className} roadMapData={roadMapData} /> {/* progress bar roadmap */}
      <ul className={styles.list}>
        {roadMapData.map(({ title, description, isReady }, index) => (
          <li key={index} className={styles.item}>
            {isReady ? (
              <div className={styles.checkPointWrapper}>
                <CheckPoint className={styles.checkPoint} width={24} height={24} />
              </div>
            ) : (
              <PointIcon className={styles.pointIcon} width={16} height={16} />
            )}
            <div className={styles.wrapper}>
              <p className={styles.title}>{title}</p>
              {description && <p className={styles.description}>{description}</p>}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
