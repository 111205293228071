'use client';
import { useScroll, useTransform, motion } from 'framer-motion';
import PoolDesktopIcon from '@/public/poolDesktop.svg';
import styles from './BackgroundAnimation.module.scss';

export const BackgroundAnimation = () => {
  const { scrollYProgress } = useScroll();
  const height = useTransform(scrollYProgress, [0, 1], ['0px', '2100px']);
  return (
    <div className={`${styles.iconWrapper}`}>
      <PoolDesktopIcon className={styles.iconDesktop} />
      <motion.div className={styles.progressBar} style={{ height: height }} />
    </div>
  );
};
