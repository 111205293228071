const transition = {
  duration: 1,
  ease: 'easeInOut'
};

const start = {
  y: 0,
  transition
};

export const animationOrbitVariants = {
  end: {
    y: -150,
    transition
  },
  start
};
export const animationFlaskVariants = {};
export const animationPuddleVariants = {
  end: {
    y: 100,
    transition
  },
  start
};

export const initialVariants = {
  end: {
    y: 0,
    transition
  },
  start
};
