'use client';

import Image from 'next/image';
import { motion } from 'framer-motion';
// import FlaskIcon from '@/public/icons/metrics/flask.svg';
import TubeIcon from '@/public/icons/metrics/tube.svg';
import { containerVariants, flaskVariants, tubeVariants } from './constants';
import styles from './AnimationFlask.module.scss';
import { useIsVisible } from '@/src/app/hooks/useIsVisible';

export const AnimationFlask = () => {
  const { ref, controls, handleClick } = useIsVisible({ amount: 1 });

  return (
    <motion.div
      ref={ref}
      className={styles.container}
      variants={containerVariants}
      initial='hidden'
      animate={controls}
      onClick={handleClick}
    >
      <motion.div variants={flaskVariants}>
        {/* <FlaskIcon className={styles.flask} /> */}
        <Image src='/icons/metrics/flask.png' alt='flask' width={180} height={100} className={styles.flask} />
      </motion.div>
      <motion.div variants={tubeVariants}>
        <TubeIcon className={styles.tube} />
      </motion.div>
    </motion.div>
  );
};
