import { useEffect, useState } from 'react';
import { colors } from '../constants';

export const useHeroAnimation = () => {
  const [index, setIndex] = useState(0);
  const intervalTime = 1700;

  const backgroundVariants = {
    animate: { background: colors[index], transition: { duration: 0.3 } }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % colors.length);
    }, intervalTime);
    return () => clearInterval(interval);
  }, []);

  return {
    backgroundVariants,
    index
  };
};
