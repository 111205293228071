import { AprData, InitialData, TvlData } from '@/src/app/api/types';
import { API_ENDPOINTS } from '@/src/app/constants/api';
import { testnetChainIds } from '@/src/app/constants/blockchain';
import { formatNumber } from '@/src/app/utils/formatNumber';
import { useMemo } from 'react';
import useSWR from 'swr';

export const useInfoData = (initialData: InitialData) => {
  const { data: tvls } = useSWR<TvlData[]>(API_ENDPOINTS.TVL, {
    fallbackData: initialData.tvls
  });
  const { data: aprs } = useSWR<AprData[]>(API_ENDPOINTS.APR, {
    fallbackData: initialData.aprs
  });

  const tvl: string | null = useMemo(() => {
    if (tvls?.length === 0) {
      return null;
    }
    const reducedTvl: number | null =
      tvls?.reduce(
        (acc, { tvl, lptvl, chainId }) => acc + (lptvl && !testnetChainIds.includes(chainId) ? tvl + lptvl : 0),
        0
      ) ?? null;
    return typeof reducedTvl === 'number' ? formatNumber(reducedTvl) : null;
  }, [tvls]);

  const bestApr: string | null = useMemo(() => {
    if (aprs?.length === 0) {
      return null;
    }
    const filteredData: number | null =
      aprs
        ?.filter(({ chainId, holderapr }) => !testnetChainIds.includes(chainId) && holderapr)
        ?.sort((a, b) => b.holderapr! - a.holderapr!)?.[0]?.holderapr ?? null;
    return typeof filteredData === 'number' ? formatNumber(filteredData, 2) : null;
  }, [aprs]);

  const avrLpApr: string | null = useMemo(() => {
    if (aprs?.length === 0) {
      return null;
    }
    const validAprs = aprs?.filter(({ chainId, lpapr }) => lpapr && !testnetChainIds.includes(chainId));

    if (validAprs?.length === 0) {
      return null;
    }
    const reduceLpApr: number | null = validAprs?.reduce((acc, { lpapr }) => acc + (lpapr ?? 0), 0) ?? null;

    return typeof reduceLpApr === 'number' && validAprs ? formatNumber(reduceLpApr / validAprs.length, 2) : null;
  }, [aprs]);

  const infoData = [
    { title: 'TVL', value: tvl ? `USD ${tvl}` : null },
    { title: 'best APR', value: bestApr ? `${bestApr}%` : null },
    { title: 'Avarage LP APR', value: avrLpApr ? `${avrLpApr}%` : null }
  ];

  return { infoData };
};
