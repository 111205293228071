import styles from './DesktopRoadMap.module.scss';
import PointIcon from '@/public/icons/point.svg';
import CheckPoint from '@/public/icons/checkPoint.svg';
import { RoadMapItem } from '../../types';

export const DesktopRoadMap = ({ roadMapData }: { roadMapData: RoadMapItem[] }) => {
  const isLastOrFirstInRow = (index: number) => {
    return (
      (index === 2 && roadMapData[5]?.isReady) ||
      (index === 3 && roadMapData[6]?.isReady) ||
      (index === 8 && roadMapData[5]?.isReady)
    );
  };
  return (
    <div className={styles.container}>
      {roadMapData.map(({ title, description, isReady }, index) => (
        <div
          key={index}
          className={`${styles.block} 
          ${isReady ? styles[`isReady${index + 1}`] : ''} 
          ${isLastOrFirstInRow(index) ? styles.lastOrFirstInRow : ''} 
          ${index === roadMapData.length - 1 || index === roadMapData.length - 3 ? styles.last : ''}`}
        >
          <div>
            {isReady
              ? title && (
                  <div className={styles.checkPointWrapper}>
                    <CheckPoint className={styles.checkPoint} width={32} height={32} />
                  </div>
                )
              : title && <PointIcon width={22} height={22} fill={'#fff'} className={styles.pointIcon} />}
            <div className={styles.wrapper}>
              <p className={styles.title}>{title}</p>
              <p className={styles.description}>{description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
