export const roadMapDeskDataQ2 = [
  {
    title: 'Migration voting',
    description: '',
    isReady: true
  },
  {
    title: '$YEL Migration',
    description: '',
    isReady: true
  },
  {
    title: 'Bridge Launch',
    description: '',
    isReady: true
  },
  {
    title: 'Wrap Market Launch',
    description: '',
    isReady: true
  },
  {
    title: 'Potions Launch on 1st Chain',
    description: '',
    isReady: true
  },
  {
    title: 'Liquidity Migration',
    description: '',
    isReady: true
  },
  {
    title: 'Dashboard Launch',
    description: '',
    isReady: true
  },
  {
    title: 'Potion Marketing with quest campaigns, co-marketing, DeBank campaigns',
    description: '',
    isReady: true
  },
  {
    isReady: true
  }
];

export const roadMapDeskDataQ3 = [
  {
    title: 'LP Market',
    description: '',
    isReady: true
  },
  {
    isReady: true
  },
  {
    title: 'Launch Cross-Chain WrapMarket on testnet Berachain',
    description: '',
    isReady: true
  },
  {
    isReady: true
  },
  {
    title: 'Potion Marketing with quest campaigns',
    description: '',
    isReady: true
  },
  {
    isReady: true
  },
  {
    title: 'Potions Launch on 2 more chains',
    description: '',
    isReady: true
  },
  {
    isReady: true
  },
  {
    title: 'Governance Reorganization ',
    description: '(going back to the roots of community decision making)',
    isReady: true
  }
];

export const roadMapDeskDataQ4 = [
  {
    title: 'Launch Point System',
    description: '',
    isReady: false
  },

  {
    title: 'Arbitrage opportunities for Yel holders',
    description: '',
    isReady: false
  },
  {
    title: 'Crosschain potion',
    description: '',
    isReady: false
  },
  {
    title: 'Leveraged Potions',
    description: '',
    isReady: false
  },

  {
    title: 'Potion Launcher',
    description: '',
    isReady: false
  },
  {
    title: 'First MultiBrew Potion',
    description: '',
    isReady: false
  },
  {
    isReady: false
  },
  {
    isReady: false
  },
  {
    isReady: false
  }
];
