'use client';

import { motion } from 'framer-motion';
import PuddleIcon from '@/public/icons/metrics/puddle.svg';
import {
  containerVariants,
  puddleVariants,
  secondBubleleVariants,
  firstBubleleVariants,
  thirdBubleleVariants,
  fourBubleleVariants,
  fiveBubleleVariants,
  sixBubleleVariants
} from './constants';
import { useIsVisible } from '@/src/app/hooks/useIsVisible';
import styles from './AnimationPuddle.module.scss';

export const AnimationPuddle = () => {
  const { ref, controls, handleClick } = useIsVisible({ amount: 0.5 });

  return (
    <motion.div
      ref={ref}
      className={styles.container}
      variants={containerVariants}
      initial='hidden'
      animate={controls}
      onClick={handleClick}
    >
      <motion.div variants={puddleVariants}>
        <PuddleIcon className={styles.puddle} />
      </motion.div>
      <motion.div className={styles.firstBubble} variants={firstBubleleVariants}></motion.div>
      <motion.div className={styles.fourBubble} variants={fourBubleleVariants}></motion.div>
      <motion.div className={styles.thirdBubble} variants={thirdBubleleVariants}></motion.div>
      <motion.div className={styles.sixBubble} variants={sixBubleleVariants}></motion.div>
      <motion.div className={styles.secondBubble} variants={secondBubleleVariants}></motion.div>
      <motion.div className={styles.fiveBubble} variants={fiveBubleleVariants}></motion.div>
    </motion.div>
  );
};
