'use client';
import { SectionTitle } from '../../components/SectionTitle/SectionTitle';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SocialNetworks } from '../../components/SocialNetworks/SocialNetworks';
import { SocialNetworkVariants } from '../../components/SocialNetworks/types';
import styles from './RoadMap.module.scss';
import { roadmapData } from './constants';
import { getCurrentQuarter } from '../../utils/getCurrentQuarter';

export const RoadMap = () => {
  const { year, quarter } = getCurrentQuarter();
  const indexInitialSlide = roadmapData.findIndex(({ period }) => period.year === year && period.quarter === quarter);
  const newRoadMapData = [
    ...roadmapData.slice(indexInitialSlide, roadmapData.length),
    ...roadmapData.slice(0, indexInitialSlide)
  ];

  return (
    <section className={`${styles.container}`}>
      <Swiper
        navigation={true}
        modules={[Navigation, Autoplay, Pagination]}
        className={`${styles.swiper}`}
        autoplay={{ delay: 7000, disableOnInteraction: false }}
        speed={1000}
        loop={true}
        pagination={{ clickable: true }}
      >
        {newRoadMapData.map(({ title, children }, index) => (
          <SwiperSlide key={index}>
            <div className={`container ${styles.slide}`}>
              <SectionTitle>{title}</SectionTitle>
              <div className={styles.wrapper}>{children}</div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <SocialNetworks variant={SocialNetworkVariants.FOOTER} />
    </section>
  );
};
