import useSWR from 'swr';
import { useMemo } from 'react';
import { AprData, InitialData } from '@/src/app/api/types';
import { API_ENDPOINTS } from '@/src/app/constants/api';
import { testnetChainIds } from '@/src/app/constants/blockchain';
import { colors, stub } from './constants';

export const useVaultsData = (initialData: InitialData) => {
  const { data: aprs } = useSWR<AprData[]>(API_ENDPOINTS.APR, {
    fallbackData: initialData.aprs
  });
  const filteredAprs = useMemo(() => {
    if (aprs?.length === 0) {
      return stub;
    }
    return aprs
      ?.filter(({ chainId, holderapr }) => !testnetChainIds.includes(chainId) && holderapr)
      ?.sort((a, b) => b.holderapr! - a.holderapr!)
      ?.slice(0, 3)
      .map((item, index) => {
        const foundAsset =
          initialData?.assets?.find((asset) => asset?.address?.toLowerCase() === item?.address?.toLowerCase()) ?? [];
        return { ...item, ...foundAsset, color: colors[index], title: 'APR' };
      });
  }, [aprs, initialData.assets]);
  return { filteredAprs };
};
