import { DesktopRoadMap } from './components/DesktopRoadMap/DesktopRoadMap';
import { MobileRoadMap } from './components/MobileRoadMap/MobileRoadMap';
import { roadMapMobDataQ2, roadMapMobDataQ3, roadMapMobDataQ4 } from './components/MobileRoadMap/constants';
import { roadMapDeskDataQ2, roadMapDeskDataQ3, roadMapDeskDataQ4 } from './components/DesktopRoadMap/constants';
import styles from './RoadMap.module.scss';

export const roadmapData = [
  {
    title: "Roadmap Q2 '24",
    children: (
      <>
        <MobileRoadMap roadMapData={roadMapMobDataQ2} className={styles.progressQ2} />
        <DesktopRoadMap roadMapData={roadMapDeskDataQ2} />
      </>
    ),
    period: { year: 2024, quarter: 2 }
  },
  {
    title: "Roadmap Q3 '24",
    children: (
      <>
        <MobileRoadMap roadMapData={roadMapMobDataQ3} className={styles.progressQ3} />
        <DesktopRoadMap roadMapData={roadMapDeskDataQ3} />
      </>
    ),
    period: { year: 2024, quarter: 3 }
  },
  {
    title: "Roadmap Q4 '24",
    children: (
      <>
        <MobileRoadMap roadMapData={roadMapMobDataQ4} className={styles.progressQ4} />
        <DesktopRoadMap roadMapData={roadMapDeskDataQ4} />
      </>
    ),
    period: { year: 2024, quarter: 4 }
  }
];
