export const calculatePosition = (index: number, total: number, radius: number) => {
  const angle = (index / total) * 360;
  const radians = (angle * Math.PI) / 180;
  const x = radius * Math.cos(radians);
  const y = radius * Math.sin(radians);
  return { x, y };
};

export const getRadiusData = (windowWidth: number) => {
  if (windowWidth < 560) {
    return 180;
  } else if (windowWidth < 768) {
    return 240;
  } else if (windowWidth < 1024) {
    return 300;
  } else if (windowWidth < 1440) {
    return 340;
  } else return 400;
};
